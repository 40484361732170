const callout = {
  render: 'Callout',
  attributes: {
    method: {
      type: String,
    },
    bgColor: {
      type: String,
    }
  },
};

export default callout;
