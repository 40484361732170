import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { xcode } from "react-syntax-highlighter/dist/esm/styles/hljs";

type CodeProps = {
  children: any;
  language: string;
};

function Code({ children, language }: CodeProps) {
  return (
    <>
      <SyntaxHighlighter
        className="syntaxHighlighter"
        language={language}
        style={xcode}
      >
        {String(children?.props?.children)}
      </SyntaxHighlighter>
    </>
  );
}

export default Code;
