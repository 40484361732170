import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Navigate } from "react-router-dom";
import RouterNav from "../Router";

const ProtectedRoute = () => {
  const [loggedIn, setLoggedIn] = useState(true);

  useEffect(() => {
    const isCurrentAuthenticate = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setLoggedIn(true);
      } catch (e) {
        setLoggedIn(false);
      }
    };
    isCurrentAuthenticate();
  }, []);

  return <>{loggedIn ? <RouterNav /> : <Navigate to="/signin" />}</>;
};

export default ProtectedRoute;
