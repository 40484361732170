const response = {
    render: 'Response',
    attributes: {
      style: {
        type: String,
      },
    },
  };
  
  export default response;
  