import React, { useEffect, useState } from "react";
import Markdoc from "@markdoc/markdoc";
import Callout from "./Callout";
import Code from "./Code";
import Item from "./Item";
import Response from "./Response";
import callout from "../markdoc/callout.markdoc";
import code from "../markdoc/code.markdoc";
import item from "../markdoc/item.markdoc";
import response from "../markdoc/response.markdoc"

type MarkdownProps = {
  markdown: string;
};

const Dashboard = ({ markdown }: MarkdownProps) => {
  const [md, setMd] = useState("");

  useEffect(() => {
    if (markdown) {
      fetch(markdown)
        .then((res) => res.text())
        .then((text) => setMd(text));
    }
    return () => {
      setMd("");
    };
  }, [markdown]);

  const ast = Markdoc.parse(md);
  const content = Markdoc.transform(ast, {
    tags: { callout, code, item,response },
  });
  const component = { Callout, Code, Item, Response};
  const reactNode = Markdoc.renderers.react(content, React, {
    components: component,
  });

  return <React.Fragment>{reactNode}</React.Fragment>;
};

export default Dashboard;
