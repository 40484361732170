import React from "react";

type ResponseProps = {
  children: any;
  style: string;
};

function Response({ children, style }: ResponseProps) {

  return (
    <>
      <div className={style}>
          <span>
            {children}
          </span>
        </div>
    </>
  );
}

export default Response;
