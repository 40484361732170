import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import SideBar from "./components/SideBar";
import flowride from "./pages/flowride.md";
import rest from "./pages/restapi.md";
import mqtt from "./pages/mqtt.md";
import telemetry from "./pages/telemetry.md";
import event from "./pages/event.md";
import connected from "./pages/connected.md";
import command from "./pages/command.md";
import disengagements from "./pages/disengagements.md";
import location from "./pages/location.md";
import authority from "./pages/authority.md";
import vehicle from "./pages/vehicle.md";
import vehicleData from "./pages/vehicleData.md";
import destination from "./pages/destination.md";
import openapi from "./pages/openapi.md";

const RouterNav = () => {
  return (
    <>
      <div className="App">
        <SideBar />
        <main>
          <Routes>
            <Route path="/" element={<Dashboard markdown={flowride} />} />
            <Route path="/rest" element={<Dashboard markdown={rest} />} />
            <Route path="/command" element={<Dashboard markdown={command} />} />
            <Route
              path="/disengagements"
              element={<Dashboard markdown={disengagements} />}
            />
            <Route
              path="/location"
              element={<Dashboard markdown={location} />}
            />
            <Route
              path="/authority"
              element={<Dashboard markdown={authority} />}
            />
            <Route path="/vehicle" element={<Dashboard markdown={vehicle} />} />
            <Route
              path="/vehicleData"
              element={<Dashboard markdown={vehicleData} />}
            />
            <Route
              path="/destination"
              element={<Dashboard markdown={destination} />}
            />
            <Route path="/openapi" element={<Dashboard markdown={openapi} />} />
            <Route path="/mqtt" element={<Dashboard markdown={mqtt} />} />
            <Route
              path="/telemetry"
              element={<Dashboard markdown={telemetry} />}
            />
            <Route path="/event" element={<Dashboard markdown={event} />} />
            <Route
              path="/connection"
              element={<Dashboard markdown={connected} />}
            />
          </Routes>
        </main>
      </div>
    </>
  );
};

export default RouterNav;
