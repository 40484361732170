import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Amplify from "aws-amplify";
import Login from "./components/Login";
import COGNITO from "./configs/aws";
import ProtectedRoute from "./components/ProtectedRoute";

Amplify.configure({
  aws_cognito_region: COGNITO.REGION,
  aws_user_pools_id: COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
});

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/signin" element={<Login />} />
        <Route path="*" element={<ProtectedRoute />} />
      </Routes>
    </Router>
  );
};

export default App;
