const item = {
  render: "Item",
  attributes: {
    item: {
      type: String,
    },
  },
};

export default item;
