import React from "react";
import { NavLink } from "react-router-dom";
import {
  HiOutlineChevronDoubleRight,
  HiOutlineChevronDoubleLeft,
} from "react-icons/hi2";
import { TbApi, TbMessageShare } from "react-icons/tb";
import { SiFastapi, SiSwagger } from "react-icons/si";
import { HiOutlineStatusOnline } from "react-icons/hi";
import { MdBusAlert } from "react-icons/md";
import { ImConnection, ImCommand } from "react-icons/im";
import {
  BsStopCircle,
  BsFillPeopleFill,
  BsInfoCircleFill,
  BsClipboardData,
} from "react-icons/bs";
import { BiCurrentLocation } from "react-icons/bi";
import { FaRoute } from "react-icons/fa";
import { useState } from "react";
import Collapse from "@material-ui/core/Collapse";

const navData = [
  {
    id: 1,
    icon: <SiFastapi className="bigIcon" />,
    text: "Flowride API",
    link: "/",
  },
  {
    id: 2,
    icon: <TbApi className="bigIcon" />,
    text: "Rest API",
    link: "/rest",
    subItem: [
      {
        id: 21,
        icon: <ImCommand className="smallIcon" />,
        text: "Command",
        link: "/command",
      },
      {
        id: 22,
        icon: <BsStopCircle className="smallIcon" />,
        text: "Disengagements",
        link: "/disengagements",
      },
      {
        id: 23,
        icon: <BiCurrentLocation className="smallIcon" />,
        text: "Location",
        link: "/location",
      },
      {
        id: 24,
        icon: <BsFillPeopleFill className="smallIcon" />,
        text: "Authority",
        link: "/authority",
      },
      {
        id: 25,
        icon: <BsInfoCircleFill className="smallIcon" />,
        text: "Vehicle",
        link: "/vehicle",
      },
      {
        id: 26,
        icon: <BsClipboardData className="smallIcon" />,
        text: "Vehicle Data",
        link: "/vehicleData",
      },
      {
        id: 27,
        icon: <FaRoute className="smallIcon" />,
        text: "Destination",
        link: "/destination",
      },
      {
        id: 28,
        icon: <SiSwagger className="smallIcon" />,
        text: "OpenAPI",
        link: "/openapi",
      },
    ],
  },
  {
    id: 3,
    icon: <TbMessageShare className="bigIcon" />,
    text: "MQTT",
    link: "/mqtt",
    subItem: [
      {
        id: 31,
        icon: <HiOutlineStatusOnline className="smallIcon" />,
        text: "Telemetry",
        link: "/telemetry",
      },
      {
        id: 32,
        icon: <MdBusAlert className="smallIcon" />,
        text: "Event",
        link: "/event",
      },
      {
        id: 33,
        icon: <ImConnection className="smallIcon" />,
        text: "Connection",
        link: "/connection",
      },
    ],
  },
];

function Sidenav() {
  const [open, setOpen] = useState(true);
  const [active, setActive] = useState(true);
  const toggleOpen = () => {
    setOpen(!open);
  };
  const toggleActive = (item: any) => {
    if (item.hasOwnProperty("subItem")) {
      setActive(!active);
    }
  };

  return (
    <div className={open ? "sidenav" : "sidenavClosed"}>
      <button className="menuBtn" onClick={toggleOpen}>
        {open ? (
          <HiOutlineChevronDoubleLeft className="bigIcon" />
        ) : (
          <HiOutlineChevronDoubleRight className="bigIcon" />
        )}
      </button>
      {navData.map((item) => {
        return (
          <>
            <NavLink
              key={item.id}
              className="sideitem"
              to={item.link}
              onClick={() => toggleActive(item)}
            >
              {item.icon}
              <span className="linkText">{item.text}</span>
            </NavLink>
            <Collapse in={active} timeout="auto" unmountOnExit>
              {item &&
                item?.subItem?.map((sub) => {
                  return (
                    <NavLink key={sub.id} className="sideitem" to={sub.link}>
                      {sub.icon}
                      <span className="sublinkText">{sub.text}</span>
                    </NavLink>
                  );
                })}
            </Collapse>
          </>
        );
      })}
    </div>
  );
}

export default Sidenav;
