import React, { useState } from "react";
import useInput from "./../utils/forms";
import Toast from "./../utils/notifications";
import { Auth } from "aws-amplify";
import {
  Button,
  Card,
  TextField,
  CircularProgress,
  InputAdornment,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { MdOutlineEmail, MdOutlineLock } from "react-icons/md";

const Signup = () => {
  const [loading, setLoading] = useState(false);
  let history = useNavigate();
  const { value: email, bind: bindEmail } = useInput("");
  const { value: password, bind: bindPassword } = useInput("");

  const handleSubmit = async (e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    setLoading(true);

    try {
      await Auth.signIn(email, password);
      Toast("Success", "Login Successfully !", "success");
      history("/");
    } catch (e) {
      Toast("Error", e.message, "danger");
    }

    setLoading(false);
  };

  return (
    <div className="background">
      <Card style={{ width: 400, margin: "100px auto", padding: "40px" }}>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          onSubmit={handleSubmit}
        >
          <img src={process.env.PUBLIC_URL + "/logo-full.png"} alt="logo" />
          <h1 className="login-header">Flowride AI API Documentation</h1>
          <TextField
            label="Email"
            {...bindEmail}
            type="text"
            variant="outlined"
            style={{ margin: "30px 25px 0px 25px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MdOutlineEmail />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Password"
            type="password"
            {...bindPassword}
            variant="outlined"
            style={{ margin: "20px 25px 20px 25px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MdOutlineLock />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="inherit"
            size="large"
            type="submit"
            disabled={loading}
            style={{ margin: "0px 25px 20px 25px", backgroundColor: "#ef6932" }}
          >
            {loading && (
              <CircularProgress size={20} style={{ marginRight: 20 }} />
            )}
            Login
          </Button>
        </form>
      </Card>
    </div>
  );
};

export default Signup;
