import React from "react";

type CalloutProps = {
  children: String;
  method: String;
  bgColor: string;
};

function Callout({ children, method, bgColor }: CalloutProps) {

  return (
    <>
      {method !== undefined ? (
        <div className="callout-method">
          <span className="callout-child">
            <span className="method" style={{backgroundColor: bgColor}}>{method}&nbsp;</span>
            {children}
          </span>
        </div>
      ) : (
        <div className="callout">
          <span>{children}</span>
        </div>
      )}
    </>
  );
}

export default Callout;
