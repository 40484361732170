import React from "react";
import { Grid } from "@mui/material";

type ItemProps = {
  children: any;
};

function Item({ children }: ItemProps) {

  let item1 = [];
  let item2 = [];
  let indexUl = 0;
  if (children !== undefined) {
    for (let i = 0; i < children.length - 1; i++) {
      if (children[i].type === "ul") {
        indexUl = i;
        break;
      }
    }
    item1 = children.slice(0, indexUl);
    item2 = children.slice(indexUl + 1);
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={7} md={7} className="right-item">
          {item1}
        </Grid>
        <Grid item xs={5} md={5} className="left-item">
          {item2}
        </Grid>
      </Grid>
    </>
  );
}

export default Item;
