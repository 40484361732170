const code = {
  render: 'Code',
  attributes: {
    language: {
      type: String,
    },
  },
};

export default code;
